//
// Build the jump menu on the fly
//

function buildJumpMenu() {
	let navContainer = document.querySelector('.jump-menu');
	// If there's not already a jump menu, then build one
	if (navContainer) {
		console.log('Jump menu exists already.');
		return false;
	} else {
		console.log('Building the jump menu.');
		// Get the list of jump items
		let jumpItems = document.querySelectorAll('[data-jump-link]');
		// Create an unordered list
		let list = document.createElement('ul');
		// Create a fragment
		let fragment = document.createDocumentFragment();
		// Create a list item for each jump item
		// and append it to the fragment
		jumpItems.forEach(function(item){
			let li = document.createElement('li');
			let href = '#' + item.getAttribute('id'); // the section this will link to
			let id = item.getAttribute('data-jump-link'); // the id for this item so it can be highlighted when the corresponding section is scrolled to
			id = id.slice(1); // remove the pound sign at the beginning
			let label = item.getAttribute('data-jump-label');
			// Link markup: <a href="#Homepage" id="JumpHomepage" aria-label="Jump to Homepage"><span class="jump__label">Homepage</span></a>
			// Jump item markup: <figure id="Homepage" class="fig fig--free fig--full" data-jump-link="#JumpHomepage" data-jump-label="Homepage">
			let linkText = '<a href="' + href + '" id="' + id + '" aria-label="Jump to ' + label + '"><span class="jump__label">' + label + '</span></a>';
			li.innerHTML = linkText;
			fragment.appendChild(li);
		});
		// Append the fragement to the list
		list.appendChild(fragment);
		// Create the nav.jump-menu container
		navContainer = document.createElement('nav');
		navContainer.classList.add('jump-menu');
		navContainer.setAttribute("id", "JumpMenu");
		// Add a jump to header at the top
		// let header = document.createElement('h5');
		// let headerText = 'Jump to';
		// header.innerHTML = headerText;
		// navContainer.appendChild(header);
		// Insert the list into the nav container
		navContainer.appendChild(list);
		// Insert the nav container with list into the page
		let content = document.querySelector('.page__content');
		content.prepend(navContainer);
	}
}

//
// You are here jump menu
//

function jumpMenu() {
	
	const options = {
	  rootMargin: '-2px 0px -95% 0px', // Need the top margin to make you-are-here work with scroll-top-margin in css
	  threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
	}

	// Register IntersectionObserver
	const io = new IntersectionObserver((entries) => {
	  entries.forEach((entry) => {
		// what's the corresponding jump link for this observed item?
		var jl = entry.target.dataset.jumpLink;
		var link = document.querySelector(jl);
		if (entry.intersectionRatio > 0) {
		  // Add 'active' class if observation target is inside viewport
		  entry.target.classList.add('is-active');
		  // Add 'is-current' class to corresponding jump link
		  link.classList.add('is-current');
		  // Track whether this node has been reached or not
		  link.parentNode.classList.add('is-reached');
		} else {
		  // Remove 'active' class otherwise
		  entry.target.classList.remove('is-active');
		  // Remove 'is-current' class on corresponding jump link
		  link.classList.remove('is-current');
		}
	  })
	}, options);
	
	// Declares what to observe, and observes its properties.
	const sections = document.querySelectorAll('[data-jump-link]');
	if (sections.length === 0) {
		console.log('no jump sections found');
	} else {
		sections.forEach((el) => {
		  io.observe(el);
		});
	}
}

//
// Jump menu toggle
//

function jumpMenuToggle() {
	let menuToggle = document.querySelector('.jump-menu-toggle');
	let h = document.querySelector('html');
	h.classList.add('is-jump-menu-closed');
	
	menuToggle.onclick = function(e) {
		e.preventDefault;
		if (h.classList.contains('is-jump-menu-closed')) {
			h.classList.remove('is-jump-menu-closed');
			h.classList.add('is-jump-menu-open');
		} else {
			h.classList.remove('is-jump-menu-open');
			h.classList.add('is-jump-menu-closed');
		}
	}
}

//
// At the top?
//

function atTop() {
	let top = document.querySelector('#Top');
	let topHeight = top.offsetHeight;
	let h = document.querySelector('html');
	window.addEventListener('scroll', function() {
    	let scroll = window.scrollY;
    	if (scroll > topHeight) {
        	h.classList.add('is-not-at-top');
    	} else {
        	h.classList.remove('is-not-at-top');
    	}
	}, false);
}

//
// Enlarge figures
//

function enlargeFigs() {
	// The html node
	let h = document.querySelector('html');
	// The modal container
	let m = document.querySelector('.enlarge-modal');
	// The figs that can be enlarged
	let figs = document.querySelectorAll('[data-action="enlarge"]');
	let totalFigs = figs.length;
	if (totalFigs === 0) return;
	// Adding enlarge to the figs and an attribute to track their position
	figs.forEach((fig, index) => {
		let figNum = index + 1;
		fig.setAttribute('data-fig-number', figNum);
		if (!fig.hasAttribute('id')) {
			let figId = 'Figure' + figNum;
			fig.setAttribute('id', figId);
		}
		fig.onclick = function(e) {
			enlarge(this);
		}
	});
	// The close button
	let closeButton = document.querySelector('.enlarge__close');
	closeButton.onclick = function(e) {
		e.preventDefault;
		unEnlarge();
	}
	// The next and previous buttons
	let nextButton = document.querySelector('.enlarge__next');
	let previousButton = document.querySelector('.enlarge__prev');
	if (totalFigs === 1) {
		console.log("removing next and previous buttons because we don't need them");
		nextButton.remove();
		previousButton.remove();
	} else {
		nextButton.onclick = function(e) {
			e.preventDefault;
			enlargeNext();
		}
		previousButton.onclick = function(e) {
			e.preventDefault;
			enlargePrevious();
		}
	}
	// Close the modal and reset the enlargement
	function unEnlarge() {
		let current = document.querySelector('.fig.is-enlarged');
		h.classList.remove('is-enlarge-modal-active');
		current.classList.remove('is-enlarged');
		m.innerHTML = '';
	}
	// Enlarge the chosen image
	function enlarge(fig) {
		if (fig) {
			// set states on html and to-be-enlarged figure
			h.classList.add('is-enlarge-modal-active');
			fig.classList.add('is-enlarged');
			// reset the modal
			m.innerHTML = '';
			// clone the to-be-enlarged figure, clean up some attributes and inject it into the modal
			let clone = fig.cloneNode(true);
			clone.id = 'ModalFig';
			clone.classList.remove('is-active');
			clone.classList.remove('is-enlarged');
			clone.removeAttribute('data-jump-link');
			clone.removeAttribute('data-jump-label');
			clone.removeAttribute('data-action');
			m.appendChild(clone);
			// reset the lazyloading on the clone
			let cloneImg = clone.querySelector('img');
			cloneImg.setAttribute('sizes', '95vw');
			cloneImg.classList.remove('lazyloaded');
			cloneImg.classList.add('lazyload');
			// close the modal when clicked
			let mf = document.querySelector('#ModalFig');
			mf.onclick = function(e) {
				e.preventDefault;
				unEnlarge();
			}
			// Track the current image
			let counterText = fig.getAttribute('data-fig-number') + ' of ' + totalFigs;
			let counter = document.createElement('div');
			counter.classList.add('enlarge-modal__counter', 'is-small');
			counter.innerHTML = counterText;
			m.appendChild(counter);
			// console.log(fig.getAttribute('data-fig-number') + ' of ' + totalFigs);
			// move the page to the currently enlarged image
			goToFig(fig.getAttribute('id'));
		} else {
			console.error('no figure to enlarge');
		}
	}
	// Get the next sibling element
	function getNextSibling(elem, selector) {
		// Get the next sibling element
		let sibling = elem.nextElementSibling;
		// If there's no selector, return the first sibling
		if (!selector) return sibling;
		// If the sibling matches our selector, use it
		// If not, jump to the next sibling and continue the loop
		while (sibling) {
			if (sibling.matches(selector)) return sibling;
			sibling = sibling.nextElementSibling
		}
	}
	// Get the next figure
	function getNextFig(elem) {
		let elemNum = elem.getAttribute('data-fig-number');
		let elemIndex = elemNum - 1;
		if (elemIndex === totalFigs) {
			return false;
		} else {
			return figs[elemIndex + 1];
		}
	}
	// Get the previous sibling element
	function getPreviousSibling(elem, selector) {
		// Get the previous sibling element
		let sibling = elem.previousElementSibling;
		// If there's no selector, return the first sibling
		if (!selector) return sibling;
		// If the sibling matches our selector, use it
		// If not, jump to the next previous sibling and continue the loop
		while (sibling) {
			if (sibling.matches(selector)) return sibling;
			sibling = sibling.previousElementSibling;
		}
	}
	// Get the previous figure
	function getPreviousFig(elem) {
		let elemNum = elem.getAttribute('data-fig-number');
		let elemIndex = elemNum - 1;
		if (elemNum === 0) {
			return false;
		} else {
			return figs[elemIndex - 1];
		}
	}
	// Enlarge the next sibling fig
	function enlargeNext() {
		let current = document.querySelector('.fig.is-enlarged');
		// Get the next enlarge-able sibling
		// let next = getNextSibling(current, '[data-action="enlarge"]');
		let next = getNextFig(current);
		// If there's a next, enlarge it
		// If not, loop back to the first fig and enlarge that
		if (next) {
			current.classList.remove('is-enlarged');
			enlarge(next);
			// console.log(current.getAttribute('data-jump-label') + ' => ' + next.getAttribute('data-jump-label'));
		} else {
			current.classList.remove('is-enlarged');
			next = figs[0];
			enlarge(next);
			// console.log(current.getAttribute('data-jump-label') + ' => ' + next.getAttribute('data-jump-label'));
		}
	}
	// Enlarge the previous sibling fig
	function enlargePrevious() {
		// let m = document.querySelector('.enlarge-modal');
		let current = document.querySelector('.fig.is-enlarged');
		// Get the previous enlarge-able sibling
		// let previous = getPreviousSibling(current, '[data-action="enlarge"]');
		let previous = getPreviousFig(current);
		// If there's a previous, enlarge it
		// If not, loop back to the last fig and enlarge that
		if (previous) {
			current.classList.remove('is-enlarged');
			enlarge(previous);
			// console.log(current.getAttribute('data-jump-label') + ' => ' + previous.getAttribute('data-jump-label'));
		} else {
			current.classList.remove('is-enlarged');
			previous = figs[figs.length - 1];
			enlarge(previous);
			// console.log(current.getAttribute('data-jump-label') + ' => ' + previous.getAttribute('data-jump-label'));
		}
	}
	// Move the page behind the modal to match the currently viewed figure
	function goToFig(id) {
		if (!id) {
			console.error('no id supplied');
			return;
		} else {
			let url = getNextUrl(id);		
			window.location.href = url;
		}
	}
	// Make a new url with the supplied id appended as an anchor
	function getNextUrl(id) {
		let currentUrl = window.location.href;
		let splitUrl = currentUrl.split('#');
		let nextUrl = splitUrl[0] + '#' + id;
		return nextUrl;
	}
}

//
// Jump menu links
// Make sure the menu closes once the links are clicked
//

function jumpMenuLinks() {
	let jumpMenuLinks = document.querySelectorAll('.jump-menu > ul > li');
	let h = document.querySelector('html');
	
	jumpMenuLinks.forEach((link, index) => {
		link.onclick = function(e) {
			h.classList.remove('is-jump-menu-open');
			h.classList.add('is-jump-menu-closed');
		}
	});
}

//
// Toggling the light mode

function modeToggle() {
	let mt = document.querySelector('.mode-toggle');
	let h = document.querySelector('html');
	
	mt.onclick = function(e) {
		e.preventDefault;
		if (h.classList.contains('is-light-mode')) {
			h.classList.remove('is-light-mode');
			localStorage.removeItem('snLightMode');
		} else {
			h.classList.add('is-light-mode');
			localStorage.setItem('snLightMode', true);
		}
	}
};

//
// Init
//

function initPage(){
	// Various init functions
	buildJumpMenu();
	jumpMenu();
	jumpMenuToggle();
	atTop();
	enlargeFigs();
	jumpMenuLinks();
	modeToggle();
	
	// Check if we should set the site into light mode
	let h = document.querySelector('html');
	let lightModeStatus = localStorage.getItem('snLightMode');
	if (lightModeStatus) {
		h.classList.add('is-light-mode');
	} else {
		h.classList.remove('is-light-mode');
	}

};

initPage();